import '../../styles/pages/fall-winter-2022/california-condors.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import { withPrefix } from 'gatsby';
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/fall-winter-2022-stories/more-stories"
import { Parallax, Background } from 'react-parallax';

import socialBanner from "../../images/fall-winter-2022/social-banners/california-condors.jpg";

import titleGraphic from '../../images/fall-winter-2022/california-condors/title-graphic.svg';
import condor from '../../images/fall-winter-2022/california-condors/condor-in-flight.png';
import ibarraRadio from '../../images/fall-winter-2022/california-condors/ibarra-radio.png';
import condor2 from '../../images/fall-winter-2022/california-condors/condor-in-flight-2.png';
// import hills1 from '../../images/fall-winter-2022/california-condors/hills-1.svg';
// import hills2 from '../../images/fall-winter-2022/california-condors/hills-2.svg';
// import hills3 from '../../images/fall-winter-2022/california-condors/hills-3.svg';
import condorAboveHills from '../../images/fall-winter-2022/california-condors/condor-above-hills.jpg';
import ibarraTree from '../../images/fall-winter-2022/california-condors/ibarra-tree.jpg';
import group from '../../images/fall-winter-2022/california-condors/group.jpg';
import ibarraCommencement from '../../images/fall-winter-2022/california-condors/ibarra-commencement.jpg';
import ibarraHoese from '../../images/fall-winter-2022/california-condors/ibarra-hoese.jpg';

import group1 from '../../images/fall-winter-2022/california-condors/group-1.jpg';
import group2 from '../../images/fall-winter-2022/california-condors/group-2.jpg';
import group3 from '../../images/fall-winter-2022/california-condors/group-3.jpg';
import group4 from '../../images/fall-winter-2022/california-condors/group-4.jpg';
import group5 from '../../images/fall-winter-2022/california-condors/group-5.jpg';
import group6 from '../../images/fall-winter-2022/california-condors/group-6.jpg';

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    var pageTitle = 'Safe Passage for California Condors';
    var pageDesc = 'A Cal State Fullerton alumna sets her sights on protecting critically endangered species like the California condor.';
    var slug = 'california-condors';

    var heroStyles = {
      backgroundColor: '#222',
      // backgroundImage: `url(${hero})`,
      // backgroundRepeat: 'no-repeat',
      // backgroundPosition: 'center center',
      // backgroundSize: 'cover',
      color: 'white',
      height: '100vh'
    }

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="fall-winter-2022">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={`fall-winter-2022 ${slug}`} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>
        <div className="hero story animated fadeIn" style={heroStyles}>
          <div className="text">
            <h1 className="animated fadeIn delay-1s slower"><img src={titleGraphic} alt={pageTitle} /></h1>
            <p className="sub-heading animated fadeInDown delay-1s">A Cal State Fullerton alumna sets her sights on protecting critically endangered species like the California condor.</p>
            <span className="animated fadeInDown delay-1s byline">By Taylor Arrey, Photos by Matt Gush</span>
          </div>
          {/* <div className="tint"></div> */}
          <video id="heroVideo" autoPlay="autoplay" loop muted="muted" >
            <source src={withPrefix('/video/fall-winter-2022/california-condors.webm')} type="video/webm; codecs=vp9,vorbis" />
            <source src={withPrefix('/video/fall-winter-2022/california-condors.mp4')} />
            <track kind="captions" srcLang="en" label="English" src={withPrefix('/video/fall-winter-2022/california-condors.vtt')} />
          </video>
        </div>
        <div id="story-intro">
          <div class="wrap">
            <div class="row">
              <div class="col-7" style={{zIndex: '2', position: 'relative'}}>
                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                  <p><span className="intro-text">With a wingspan that stretches over nine feet,</span> blunt claws and a distinctive hooked beak, the California condor soars through the sky with a beauty and grace that was almost lost to the world.</p>

                  <p>In fact, the last California condor in the wild was captured at Bitter Creek National Wildlife Refuge in 1987. </p>
                </ScrollAnimation>
                
                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                  <p>Only 27 California condors remained in captivity — the last of their species — but that wasn’t the end of their story. After years of tireless conservation efforts and captive breeding programs, these majestic scavengers made a triumphant return to the wild.</p>

                  <p>Though still endangered, more than 500 condors thrive in the United States today, with help from the U.S. Fish and Wildlife Service, the Santa Barbara Zoo and CSUF-trained conservationists.</p>
                </ScrollAnimation>
              </div>
              <div class="col-5">
                <img src={condor} alt="" id="condor-in-flight" />
              </div>
            </div>
          </div>
        </div>
        <div id="working-in-a-wildlife-refuge" className='clear-fix'>
          <div className='wrap'>
            <div className='wrap small right-text'>
            
              <img src={ibarraRadio} alt="Jennifer Ibarra"  aria-labelledby="ibarra-radio-caption" id="ibarra-radio" />

              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <h2>Working in a Wildlife Refuge</h2>
  
              <p>Before the sun has a chance to light the skyline of Bitter Creek National Wildlife Refuge in Maricopa, California, CSUF alumna and field technician Jennifer Ibarra ’20 (B.S. biological science) hikes along dirt roads with a radio — scanning the skies for California condors. </p>
              </ScrollAnimation>

              <div className="spacer" />

              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>Trudging through the dips and valleys of rugged terrain, Ibarra uses radio telemetry to detect the condors’ tracking devices, check on their nests and triangulate their location. All of this is done before she treks back to the condor flight pen — a small bunkhouse framed by miles and miles of light green grass swaying gently in the wind. </p>

              <div className="mobile-right">
              <p>On a typical day in trapping season, Ibarra sets a trap outside of the flight pen and then she waits — sometimes all day — until a condor takes the bait. Once the bird is safely in the enclosure, she helps her team tag its wing and release it back into the refuge, successfully recording one more condor flying safely through the skyline. </p> 
  
              <p>Through trapping and tagging efforts, Ibarra and her team have identified around 100 condors residing in the refuge. </p>
              
              <p id="ibarra-radio-caption">Left: Field technician Jennifer Ibarra uses radio telemetry to detect California condors. </p>
              </div>
              </ScrollAnimation>
  
            </div>
          </div>
        </div>
        <div id='grid-photos'>
          <img src={condorAboveHills} alt="" />
          <img src={ibarraTree} alt="" />
        </div>
        <div id="working-in-a-wildlife-refuge-2" className='clear-fix'>
          <div className='wrap small'>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            
            <p>“The first condors originated in the Pleistocene Era. It’s like I'm seeing a glimpse back in time, and it's really unique to see and feel the presence of a bird that's been around for so long,” shares Ibarra.</p>

            <p>When she’s not hiking through the hills with a radio, her responsibilities include checking on nesting cameras and roosting spots. All of this work serves to protect condors from threats and keep them flying safely through the sky. </p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>The leading causes of death among condors include lead toxicosis, wildfires, electrocution from power lines and the ingestion of microtrash. Ibarra and her team have made tremendous strides in protecting condors from these threats, but she says that it’s also up to the public to be curious and learn more about condors if they want to see them survive. </p>

            <p>“They are nature's cleanup crew. They eat carcasses that are too rotted for other scavengers to pick out, and they're able to break that down and put carbon back into the environment, which is really helpful. It’s sad that they're still critically endangered even when they serve an important ecological role,” explains Ibarra.</p>
            </ScrollAnimation>
          </div>
        </div>
        <div id="empowered-by-a-titan-education">
          <div className='wrap small' style={{position: 'relative', zIndex: '2'}}>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h2>Empowered by a Titan Education</h2>

            <p>Inspired by her passion for helping animals, Ibarra joined CSUF as a biological science major with a concentration in ecology and evolutionary biology. Her original plan was to become a zookeeper, but that was before her classes and mentors opened her eyes to the endless career opportunities that exist for an aspiring biologist. </p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <blockquote>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="13" height="13"><path fill="#fff" d="M464 256h-80v-64c0-35 29-64 64-64h8c13 0 24-11 24-24V56c0-13-11-24-24-24h-8c-88 0-160 72-160 160v240c0 27 22 48 48 48h128c27 0 48-21 48-48V304c0-26-21-48-48-48zm-288 0H96v-64c0-35 29-64 64-64h8c13 0 24-11 24-24V56c0-13-11-24-24-24h-8C72 32 0 104 0 192v240c0 27 22 48 48 48h128c27 0 48-21 48-48V304c0-26-21-48-48-48z"/></svg>
              <p>I didn't think I could be a scientist, but being exposed to undergraduate research and having mentors helped me realize I can do this.</p>
            </blockquote>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“Ornithology, the study of birds, was the first organism class I took and it was impactful on my career because I learned what it was like to be in the field,” says Ibarra. </p>
            </ScrollAnimation>
            
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>When William “Bill” Hoese, professor of biological science, first met Ibarra in one of his classes, he knew that her curiosity and passion would make her a successful researcher. He encouraged her to extend her interest for ecology and environmental biology into the research field and apply for the Southern California Ecosystems Research Program (<a href="http://www.fullerton.edu/scerp/" target="_blank" rel="noreferrer">SCERP</a>), for which he is the co-director.</p> 

            <p>The undergraduate program gives a cohort of biological science students the opportunity to conduct summer research in the field and pursue independent research alongside a faculty mentor. Ibarra’s <a href="https://news.fullerton.edu/2020/06/desert-noise-research/" target="_blank" rel="noreferrer">research</a>, which was inspired by her favorite bird, examined how traffic noise and noise in urban areas disrupts the feeding patterns of Gambel’s quail and other seed-eating birds. </p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <figure className='align-right large align-outside'>
              <img src={ibarraCommencement} alt="Jennifer Ibarra" />
              <figcaption>Jennifer Ibarra ’20 (B.S. biological science); Photo by Julian Rojas</figcaption>
            </figure>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>“I didn't think I could be a scientist, but being exposed to undergraduate research and having mentors helped me realize I can do this,” Ibarra shares.</p>

            <p>Throughout her undergraduate career, Ibarra says she struggled with moments of self-doubt, but she found support and gained confidence along the way.</p>

            <p>“There would be times where my anxiety would get the better of me,” Ibarra reflects. “Professor Hoese was there to help. He’s funny and an easy person to talk to, which is what I needed to help get me started in this career.”</p>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <p>The budding biologist graduated from CSUF in 2020. After graduation, Hoese encouraged her to apply to be a Mojave Desert Monitoring technician working with tortoises.</p>

            <p>It wasn’t long after her contract ended in Nevada that she applied to be a field technician at Bitter Creek National Wildlife Refuge — a position that allowed her to revisit her passion for ornithology.</p>
            </ScrollAnimation>

          </div>
        </div>
        <div id="training-future-generations-of-conservationists" className="clear-fix">
          {/* <div className='wrap large'> */}
            <div className='row'>
              <div className='col-6'>
                <img src={condor2} alt="" id="condor-in-flight2" />

                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                <figure className='photo-grid'>
                  <img src={group1} alt="" />
                  <img src={group2} alt="" />
                  {/* <img src={group3} alt="" /> */}
                  {/* <img src={group4} alt="" /> */}
                  <img src={group5} alt="" />
                  <img src={group6} alt="" />
                  <figcaption>William Hoese, professor of biological science, takes his ornithology class to Bitter Creek National Wildlife Refuge in Maricopa, California, to learn about California condors</figcaption>
                </figure>
                </ScrollAnimation>
              </div>
              <div className='col-6'>
                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                <h2>Training Future Generations of Conservationists</h2>
                <p>While working at the refuge, Ibarra realized it was the perfect opportunity to give back to the professor and class that helped her reach her potential.</p> 
    
                <p>No stranger to field trips, in spring 2022, Hoese loaded his ornithology class into two cars for a long day at Bitter Creek National Wildlife Refuge where Ibarra, a team of biologists and a park ranger gave them an insider’s look into condor conservation. </p>
                </ScrollAnimation>

                <ScrollAnimation animateOnce={true} animateIn="fadeIn">
                <p>“Field trips are featured in all of my classes. It’s an opportunity for students to see the actual organisms in real life and meet with professionals in the field,” says Hoese, the university’s <a href="https://news.fullerton.edu/2022/03/summer-research-program-highlighted-in-2021-outstanding-professor-lecture/" target="_blank" rel="noreferrer">2021 Outstanding Professor</a>.</p>
    
                <p>Outside of seeing condors in the wild, Hoese says that his favorite proud professor moment happened when he watched Ibarra teach his students how to perform telemetry scans. </p>
                </ScrollAnimation>
              </div>
            </div>
          {/* </div> */}
        </div>
        <div className='wrap small'>
          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“Seeing my former student lead this adventure was so rewarding. Not only was she showing us how the radio worked, but she was teaching them and giving them the opportunity to learn and engage their skills in real time,” adds Hoese. </p>

          <p>Those hands-on experiences continued throughout the long day as the students maximized every learning opportunity.</p>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <figure className='align-wide'>
            <img src={ibarraHoese} alt="" />
            <figcaption>CSUF alumna Jennifer Ibarra shows William Hoese, professor of biological science, how she uses radio telemetry to track the California condors.</figcaption>
          </figure>
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“They’re identifying birds. They’re tracking them and taking notes about what they see. They’re showing each other their observations and using the scopes together,” explains Hoese. “It’s crucial that they have field experiences that encourage curiosity, participation and the application of new skill sets.” </p>

          <p>It was an exciting day full of condor sightings, hikes through the refuge and impromptu field lessons. For Ibarra, it also was a full circle moment that filled her with hope for the future.</p> 
          </ScrollAnimation>

          <ScrollAnimation animateOnce={true} animateIn="fadeIn">
          <p>“Above all else, I wanted to inspire students and give them a positive representation of what this career looks like. I hope that seeing me in the field — seeing CSUF alumni doing this important work — motivates them to keep pursuing wildlife ecology. I know what it’s like to feel defeated in this major, but there is a light.” <span className='end-mark' /></p>
          </ScrollAnimation>

        </div>
        <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
          <img src={group} alt="" style={{display: 'block'}} />
        </ScrollAnimation>
        <div id="condor-timeline">
          <div className='wrap'>
            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
            <h2>California Condor Conservation Timeline</h2>
            </ScrollAnimation>
            <ScrollAnimation animateOnce={true} animateIn="fadeInUp">
              <ul>
              
              <li>
                <span className="timeline-date">1979</span>
                <div>The Cooperative California Condor Conservation Program was established by the U.S. Fish and Wildlife Service to start captive breeding programs and reduce threats to the California Condor population.</div>
              </li>
              
              
              <li>
                <span className="timeline-date">1987</span>
                <div>The last California condor was captured at Bitter Creek National Wildlife Refuge near Maricopa, California. The 27 remaining condors were held in two breeding facilities — Los Angeles Zoo and San Diego Wild Animal Park.</div> 
              </li>
              
              <li>
                <span className="timeline-date">1988</span>
                <div>The first captive-bred condor chick hatched successfully. </div>
              </li>
              
              <li>
                <span className="timeline-date">1991</span>
                <div>
                  The federal government banned lead ammunition in hunting. 
                  <br /><br />
                  Lead toxicosis is the No.1 cause of death for California condors. As carrion predators, they consume anything that is already dead. When hunters use lead ammunition to shoot their targets and leave them in the wild, condors ingest the lead from the dead animal. 
                  <br /><br />
                  Daniel Cook, a park ranger at Bitter Creek National Wildlife Refuge, says that despite the law, many hunters still rely on lead bullets because they are cost-effective and more accessible than alternative forms of hunting ammunition.
                </div>
              </li>
              
              <li>
                <span className="timeline-date">1992</span>
                <div>Two condors were released at Hopper Mountain National Wildlife Refuge near Ventura, California.</div>
              </li>
              
              <li>
                <span className="timeline-date">Today</span>
                <div>California condors still face threats such as wildfires, electrocution from flying into power lines and ingesting microtrash. They are still critically endangered, but according to the National Park Service, there are nearly 500 condors flying throughout the country.</div>
              </li>
            </ul>
            </ScrollAnimation>

            <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p className="learn-more" style={{marginTop: '150px'}}>Support the College of Natural Sciences and Mathematics<br /><a href="https://campaign.fullerton.edu/nsm" target="_blank">campaign.fullerton.edu/nsm</a></p>
            </ScrollAnimation>

          </div>
        </div>

         

        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 